<template>
  <b-modal id="edit-cadena" title="Edit Cadena ///" ref="modal" title-class="font-22"
           hide-footer @shown="create" hide-header-close>
    <form id="form" method="POST" @submit.prevent="editCadena">
      <div class="row mx-0">
      <div class="form-group col-xl-6 ps-0 mt-3 mb-0">
        <label class="pb-2"> Cadena Id:</label>
        <b-form-input type="text"  v-model="item.url_id"></b-form-input>
<!--        <div v-if="typesubmit && $v.item.url_id.$error" class="invalid-feedback">-->
<!--          <span v-if="!$v.item.url_id.required"> The Cadena Id is required</span>-->
<!--        </div>-->
      </div>
      <div class="form-group col-xl-6 pe-0 mt-3 mb-0">
        <label class="pb-2"> Name:</label>
        <b-form-input type="text" v-model="item.name"></b-form-input>
<!--        <div v-if="typesubmit && $v.item.name.$error" class="invalid-feedback">-->
<!--          <span v-if="!$v.item.name.required"> The Name is required</span>-->
<!--        </div>-->
      </div>
      </div>
      <div class="row mx-0">
        <div class="form-group col-xl-6 ps-0 mt-3 mb-0">
          <label class="pb-2"> Type:</label>
          <b-form-input type="text"  v-model="item.type"></b-form-input>
<!--          <div v-if="typesubmit && $v.item.type.$error" class="invalid-feedback">-->
<!--            <span v-if="!$v.item.type.required"> The Type is required</span>-->
<!--          </div>-->
        </div>
        <div class="form-group col-xl-6 pe-0  mt-3 mb-0">
			<label class="pb-2"> Input Type:</label>
           <b-form-input type="text" v-model="item.input_type"></b-form-input>
<!--          <div v-if="typesubmit && $v.item.code.$error" class="invalid-feedback">-->
<!--            <span v-if="!$v.item.code.required"> The Code is required</span>-->
<!--          </div>-->
        </div>
      </div>

      <div class="form-group mt-3 mb-0">
        <label class="pb-2"> Input Content:</label>
        <b-form-input type="text"  v-model="item.input_content"></b-form-input>
<!--        <div v-if="typesubmit && $v.item.input_content.$error" class="invalid-feedback">-->
<!--          <span v-if="!$v.item.input_content.required"> The INput COntent is required</span>-->
<!--        </div>-->
      </div>

      <input type="submit" class="btn btn-primary px-3 float-end mt-3 mx-auto" variant="primary" value="Save"/>
    </form>

  </b-modal>

</template>

<script>
// import {required} from "vuelidate/lib/validators";
export default {
  name: "EditCadena",
props:['item_p'],
  data: function() {
    return {
      typesubmit:false,
      item:{},

    }
  },
  // validations: {
  //   item:{
  //     name: {required},
  //     type: {required},
  //   },
  // },
  methods: {
    create(){
      this.item = {... this.item_p}
    },
    // handleSubmit() {
    //   this.typesubmit = true;
    //   // stop here if form is invalid
    //   this.$v.$touch();
    //   if (this.$v.$invalid) {
    //     return;
    //   }
    //   this.editCadena();
    // },
    editCadena(){
      this.$http
          .post('inputs/'+this.item_p.url_id,this.item)
          .then(() => {
            this.$emit('refresh-edit',this.item)
            this.item = { }
            this.$alertify.success(" success")
            this.$refs['modal'].hide()
          })
          .catch(error => {
            console.log(error)
          })
    },
  }


}
</script>


