<template>
  <main>
    <div class="row mt-5 m-0">
      <div class="col-xl-10 m-auto">
        <div class="row mb-4">
          <div class="row mx-0">

            <h2 class="col-xl-6">Cadenas</h2>
            <div class="col-xl-6">
              <div class="searchBox float-end">
                <input class="searchInput" type="text" name="" v-model="filtre_title"   placeholder="Search">
                <button class="searchButton" href="#">
                  <i class="fa fa-search">
                  </i>
                </button>
              </div>
            </div>
        </div>
        </div>
        <div  style="border-radius:12px;border: solid 3px #32394e">
          <table class="text-center">
            <thead>
            <tr>
              <th scope="col" >#</th>
              <th scope="col" >Name</th>
              <th scope="col">Type</th>
              <th scope="col">Code</th>
              <th scope="col">Input Type</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data,index) in cadenas" :key="data.id">
              <td data-label="#"> <a :href="$url+'cadenas/'+data.url_id" target="_blank">{{data.url_id}}</a></td>
              <td data-label="Name">{{data.name}}</td>
              <td data-label="Type "> {{data.type}}</td>
              <td data-label="Code ">
                <span class="badge bg-dark m-1 text-truncate p-2" v-for="d in data.codes" :key="d.id" :title="d.code" style="max-width: 400px">{{d.code}}</span>
              </td>
              <td data-label="Input Type " :title="data.input_content"> {{data.input_type}}</td>
              <td data-label="" style="min-width:100px">
                <i class="fa fa-trash text-danger" role="button" @click="deleteItem(index)"></i>
                <i class="fa fa-edit text-info me-2" role="button" @click="showEdit(data)"></i>

              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center my-4">
          <paginate :current_page=current_page :last_page_url=last_page_url
                    v-on:change-page="changePage"/>
        </div>


      </div>
    </div>
<edit-cadena :item_p="cadena" v-on:refresh-edit="refreshEdit"/>
    <delete-confirmation type="inputs" v-if="cadenas.length"  :id="cadenas[index].url_id"  v-on:refresh-delete="cadenas.splice(index,1)"/>

  </main>
</template>

<script>
import Paginate from "@/components/pagination";
import EditCadena from "../components/EditCadena";
import DeleteConfirmation from "../components/deleteConfirmation";

export default {
  name: "Cadenas",
  components: {DeleteConfirmation, EditCadena, Paginate},
  data(){
    return{
      current_page:1,
      last_page_url:6,
      cadenas:[],
      index:'0',
      cadena:{},
      filtre_title:''
    }
  },
  watch: {
    filtre_title: function () {
      this.filterSearch(1)
    },
  },
  created(){
    this.loadCadenas(1)
  },
  methods:{
    changePage(page){
           this.loadCadenas(page)
    },
    filterSearch(page) {
      this.$http
          .post('cadenas/search?page=' + page,
              {
                title: this.filtre_title
              })
          .then(response => {
            this.cadenas = response.data.data;
            this.last_page_url = response.data.last_page;
            this.current_page = response.data.current_page
          })
          .catch(error => {
            console.log(error)
          })
    },
    loadCadenas(page) {
      this.$http
          .get('cadenas?page=' + page)
          .then(response => {
            console.log(response.data)
            this.cadenas = response.data.data;
            this.last_page_url = response.data.last_page;
            this.current_page = response.data.current_page

          })
          .catch(error => {
            console.log(error)
          })
    },
    deleteItem(index) {
      console.log("1")
      this.index= index
      this.$root.$emit('bv::show::modal', 'modal-delete')

    },

    refreshEdit(data){
      let index = this.cadenas.findIndex(ele => ele.id === this.cadena.id);
      this.cadenas[index] = data
      this.$forceUpdate();
    },
    showEdit(data){
      this.cadena = data
      this.$root.$emit('bv::show::modal', 'edit-cadena')
    }
  }
}
</script>

<style scoped  src="@/assets/table.css"></style>
<style scoped>
.searchBox {
  background: #2f3640;
  height: 40px;
  border-radius: 40px;
}
.searchBox:hover > .searchInput {
  width: 150px;
  padding: 0 16px;
}
.searchBox:hover > .searchButton {
  background: white;
  color: #2f3640;
}
.searchButton {
  color: white;
  float: end;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2f3640;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}
.searchInput {
  border: none;
  background: none;
  outline: none;
  float: start;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0px;

}

@media screen and (max-width: 620px) {
  .searchBox:hover > .searchInput {
    width: 100px;
    padding: 0 14px;
  }
}
</style>